<template>
  <div class="alpha">

    <div class="section-1">

      <div class="section-1-inner">
        <p>*Investment and insurance products and services are offered through OSAIC INSTITUTIONS, INC. Member
          FINRA/SIPC. “The Investment Center” is a trade name of AFFBB. OSAIC and AFFBB are not affiliated. Products and
          services made available through OSAIC are not insured by the NCUA or any other agency of the Bahamas
          and are not deposits or obligations of nor guaranteed or insured by the Central Bank of The Bahamas.
          These products are subject to investment risk, including the possible loss of value.</p>


        <p>1APR = Annual Percentage Rate</p>
        <p>2APY = Annual Percentage Yield. Dividends Compounded: Monthly. Dividends Credited: Monthly. Balance
          Computation Method: Dividends are calculated using the daily balance method. This method applies a daily
          periodic rate to the principal in the account each day. Rates subject to change at any time without notice.</p>

      </div>

      </div>

  </div>
</template>

<script>

export default {
  name: 'footerText',
}
</script>

<style scoped>
.section-1{
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 3%;
  margin-bottom: 2%;
  color: #071333;
}
.header{
  margin-bottom: 1%;
  color: #071333;
}
.section-1-inner p{
  padding-bottom: 10px;
}

@media (max-width: 990px) {
  .section-1{
    width: 92%;
    margin-top: 2%;
    margin-bottom: 2%;
  }
}

@media (max-width: 500px) {

}
</style>